import { UserService } from '../../services/user.service.js'

const state = {
  users: null
}

const getters = {
  users: state => state.users
}

const actions = {
  async getUsers ({ commit }) {
    await UserService.getUsers().then(response => {
      commit('SET_USERS', response.data)
    })
  },
  async addUser ({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.addUser(data).then(response => {
        if (response.data.error) reject(response.data.error)
        else {
          resolve()
          commit('SET_USERS', response.data)
        }
      })
    })
  },
  async editUser ({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.editUser(data).then(response => {
        if (response.data.error) reject(response.data.error)
        else {
          resolve()
          commit('SET_USERS', response.data)
        }
      })
    })
  },
  async deleteUser ({ commit }, data) {
    await UserService.deleteUser(data).then(response => {
      commit('SET_USERS', response.data)
    })
  }
}

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
