import { RoleService } from '../../services/role.service.js'

const state = {
  roles: null
}

const getters = {
  allRoles: state => state.roles
}

const actions = {
  async getRoles ({ commit }) {
    await RoleService.getRoles().then(response => {
      commit('SET_ROLES', response.data)
    })
  }
}

const mutations = {
  SET_ROLES: (state, payload) => {
    state.roles = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
