import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    alias: '/',
    component: () => import('@/views/Layout'),
    meta: { login: true },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: { login: true }
      },
      {
        path: '/home/:file',
        name: 'File',
        component: () => import('@/views/Home'),
        meta: { login: true }
      },
      {
        path: '/folders',
        name: 'Folders',
        component: () => import('@/views/admin/folders/Folders'),
        meta: { login: true, role: 'Admin' }
      },
      {
        path: '/folders/edit/:path',
        name: 'Edit Folder',
        component: () => import('@/views/admin/folders/EditFolder'),
        meta: { login: true, role: 'Admin' }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/admin/users/Users'),
        meta: { login: true, role: 'Admin' }
      },
      {
        path: '/users/add',
        name: 'Add User',
        component: () => import('@/views/admin/users/AddUser'),
        meta: { login: true, role: 'Admin' }
      },
      {
        path: '/users/edit/:id',
        name: 'Edit User',
        component: () => import('@/views/admin/users/EditUser'),
        meta: { login: true, role: 'Admin' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    meta: { login: false }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: { login: false }
  },
  {
    path: '/update-password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword'),
    meta: { login: false }
  },
  {
    path: '/not_authorized',
    name: '403',
    component: () => import('@/views/NoAccess.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.login === true && !store.getters.accessToken) {
    return next('/login')
  }

  if (to.meta?.login === false && store.getters.accessToken && to.path !== '/update-password') {
    return next('/')
  }

  next()
})

export default router
