import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faSearch, faHome, faSyncAlt, faCopy, faTimes, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePageTitle from 'vue-page-title'
import Multiselect from 'vue-multiselect'

import './assets/styles/index.scss'

import 'vue-multiselect/dist/vue-multiselect.min.css'

library.add(faSignOutAlt, faSearch, faHome, faSyncAlt, faCopy, faTimes, faTrashAlt, faDownload)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('multiselect', Multiselect)

Vue.use(VuePageTitle, {
  suffix: '- POM'
})

Vue.config.productionTip = false

async function main () {
  if (store.getters.accessToken && !store.getters.user) {
    await store.dispatch('getUser')
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

main()
