import http from '../http.js'
import qs from 'query-string'

export class FolderService {
  static getFiles () {
    return http.get('/folder')
  }

  static setFolderRole (data) {
    return http.post('/folder/role', qs.stringify({
      path: data.path,
      'roles[]': data.roles
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}
