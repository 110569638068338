import http from '../http.js'
import qs from 'query-string'

export class UserService {
  static getUsers () {
    return http.get('/users')
  }

  static addUser (data) {
    return http.post('/users', qs.stringify({
      email: data.email,
      name: data.name
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  static editUser (data) {
    return http.post('/user/' + data.id, qs.stringify({
      name: data.name,
      'roles[]': data.roles
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  static deleteUser (data) {
    return http.delete('/user/' + data.id)
  }
}
